<template>
  <div class="bg-black opacity-50 fixed w-full h-full z-overlay top-0 left-0" @click="close" v-if="isVisible" />
</template>

<script>
import Overlay from '@vue-storefront/core/compatibility/components/Overlay'

export default {
  mixins: [Overlay],
  beforeCreate () {
    document.documentElement.classList.add('overflow-hidden')
  },
  destroyed () {
    document.documentElement.classList.remove('overflow-hidden')
  },
  methods: {
    close () {
      this.$store.commit('ui/setOverlay', false)
      this.$store.commit('ui/setMicrocart', false)
      this.$store.commit('ui/setWishlist', false)
      this.$store.commit('ui/setSearchpanel', false)
      this.$store.commit('ui/setSidebar', false)
      this.$bus.$emit('ui-overlay-close')
    }
  }
}
</script>
