<template>
  <div class="splash-layout">
    <div id="viewport" class="w-full relative min-h-screen flex flex-col">
      <div class="background-carousel fixed top-0">
        <img class="min-h-screen object-cover" src="assets/bg-main.jpg"/>
      </div>
      <div class="main-container bg-opacity-50 text-white min-h-screen z-2 relative">
        <header class="relative container action flex items-center justify-between z-3 pt-8">
          <div class="text-center">
            <img src="assets/logo.png" class="h-12 m-auto logo" alt="Luxury Brand Partners"/>
          </div>
          <div class="flex justify-between">
            <span v-if="menuEnabled" class="ml-4 cursor-pointer" aria-label="Menu" @click="toggleMenu"><i class="material-icons block text-xl md:text-lg">menu</i></span>
            <router-link class="ml-4 cursor-pointer flex items-center justify-between" aria-label="Sign In"
                         :to="localizedRoute({name: 'sign-in'})">
              <span class="text-white text-h4 md:flex hidden uppercase py-1 px-4 bg-primary2 md:mr-5 flex items-center justify-between">
                <span class="block">Sign In</span> <i class="material-icons block text-base ml-2">login</i>
              </span>
              <i class="material-icons block text-xl md:text-lg md:hidden">login</i>
            </router-link>
          </div>
        </header>

        <nav class="menu" :class="{ 'is-open': isOpen }">
          <div class="container pt-4 flex justify-between">
            <img src="assets/logo.png" class="h-12 logo" alt="Luxury Brand Partners"/>
            <span class="ml-4 cursor-pointer" aria-label="Close" @click="toggleMenu"><i class="material-icons block text-xl md:text-lg">close</i></span>
          </div>
          <div class="container pt-10">
            <ul class="text-xl text-center text-h5 text-white font-bold uppercase leading-loose tracking-xl py-4 items-center">
              <li class="item relative m-0 p-0">
                <a href="#" class="hover:bg-grey-30 link">
                  Join Us
                </a>
              </li>
              <li class="item relative m-0 p-0 py-4 items-center">
                <a href="#" class="hover:bg-grey-30 link">
                  Item 2
                </a>
              </li>
              <li class="item relative m-0 p-0 py-4 items-center">
                <a href="#" class="hover:bg-grey-30 link">
                  Item 3
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <section class="content container mt-10 flex items-center align-center justify-center" :key="$route.path" v-if="isRestricted">
          <slot />
        </section>

        <footer class="absolute bottom-0 w-full">
          <div class="container flex items-center justify-between z-2 pb-8">
            <div class="">
              <h2
                class="action__logo text-white block text-center uppercase text-base font-bold leading-normal">
                Luxury Brand <br>Partners
              </h2>
              <span class="block text-sm uppercase">&copy; {{ currentYear }} {{ $t('All Rights Reserved.') }}</span>
            </div>
            <div class="text-right text-md uppercase">
              <router-link :to="localizedRoute({name: 'clear-cache'})" class="flex item-center">
              <span class="">{{ $t('Ver') }} {{ version }} &#10227;</span>
              </router-link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Head from 'theme/head'
import { setTimeout, clearTimeout } from 'timers'
import { onlineHelper } from '@vue-storefront/core/helpers'

export default {
  data () {
    return {
      menuEnabled: false,
      headerCompact: false,
      isScrolling: false,
      loadingFailedTimer: null,
      version: this.$store.state.version,
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current,
      isOpen: state => state.ui.mobilemenu
    }),
    isRestricted () {
      let publicRoutes = ['welcome', 'forgotpassword', 'createpassword', 'sign-in']
      return !this.currentUser || !publicRoutes.includes(this.$route.name)
    }
  },
  beforeMount () {
    this.$Progress.setColor('#00e1ba')

    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      this.$Progress.increase(40)

      if (this.loadingFailedTimer) {
        clearTimeout(this.loadingFailedTimer)
      }
      this.loadingFailedTimer = setTimeout(() => {
        if (!onlineHelper.isOnline) return

        if (to.fullPath === from.fullPath) {
          window.location.reload(true)
        } else {
          window.location.href = to.fullPath
        }
      }, 5000)

      next()
    })

    this.$router.beforeResolve((to, from, next) => {
      if (this.loadingFailedTimer) {
        clearTimeout(this.loadingFailedTimer)
      }

      next()
    })

    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
      this.$Progress.setColor('#00e1ba')
    })

    window.addEventListener('scroll', () => {
      this.isScrolling = true
    }, { passive: true })

    setInterval(() => {
      if (this.isScrolling) {
        this.hasScrolled()
        this.isScrolling = false
      }
    }, 250)
  },
  methods: {
    hasScrolled () {
      this.headerCompact = window.scrollY > 10
    },
    toggleMenu () {
      if (this.isMyAccountMenuOpen) {
        this.$store.commit('ui/setMyAccountMenu', false)
      }
      this.$store.commit('ui/setMobileMenu', !this.isOpen)
      this.$store.commit('ui/setOverlay', this.isOpen)
    }
  },
  metaInfo: Head,
  components: {

  }
}
</script>

<style lang="scss" src="theme/css/main.scss"></style>

<style scoped lang="scss">
.main-container {
  background: rgba(0,0,0,0.5);
}

.content {
  min-height: 65vh;
  padding: 1rem;
  margin-bottom: 15vh;
}

.logo {
  filter: brightness(0) invert(1);
}

.menu {
  @apply fixed w-full border-none left-0 right-0 overflow-x-hidden overflow-y-scroll z-modal;
  transition: top 0.2s cubic-bezier(0.190, 1.000, 0.220, 1.000), margin-top 0.2s cubic-bezier(0.190, 1.000, 0.220, 1.000);
  top: -120%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .9);

  &.is-open {
    @apply top-0;
  }

  .link {
    @apply font-normal text-average leading-h6 tracking-lg block py-4 items-center;
  }
}
</style>
