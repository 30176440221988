<template>
  <router-link :to="localizedRoute('/')" :title="$t('Home Page')" class="no-underline main-logo text-base text-center text-sans font-bold uppercase leading-normal">
    Luxury Brand <br>Partners<br><span class="lbp-logo-mark relative hidden pt-sm pl-xs mt-xs tracking-6px xl:inline-block">LBP</span>
  </router-link>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
<style lang="scss">
.main-logo {
  .lbp-logo-mark {
    &:before {
      width: 6px;
      height: 2px;
      content: '';
      transition: background-color .25s ease;
      transform: translateX(-50%);
      @apply absolute bg-white top-0 left-1/2;
    }
  }
}

@media screen and (max-width: theme('screens.xl')) {
  .site-header {
    .main-logo {
      @apply text-h6 leading-tight;
    }
  }
}

</style>
