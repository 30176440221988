<template>
  <div class="notifications fixed left-0 right-0 top-0 z-notification">
    <transition-group name="fade-in-down">
      <div class="notification border-box text-dark" v-for="(notification, index) in notifications" :key="`${notification.type}_${index}`"
           :class="notification.type">
        <div class="container relative px-0 py-sm">
          <div @click="execAction(notification.action1, index)"
               class="message px-13 font-bold uppercase text-center text-h5 leading-lg tracking-md"
               data-testid="notificationMessage">
            {{ mapNotificationMessage(notification.message) }}
          </div>
          <div class="actions flex justify-center">
            <div class="cursor-pointer absolute right-20px top-1/2 close mt-xs" :class="`border-${notification.type}`"
                 id="notificationAction1" data-testid="notificationAction1" @click="execAction(notification.action1, index)">
              <i class="ss-gizmo ss-delete text-dark text-2h6 opacity-40 hover:opacity-100 slow-color-change leading-none icon-lg"></i>
            </div>
            <div class="cursor-pointer py-1 px-8 notification-action uppercase text-center slow-color-change text-h5" id="notificationAction2"
                 data-testid="notificationAction2" @click="execAction(notification.action2, index)" v-if="notification.action2">
              {{ notification.action2.label }}
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@vue-storefront/i18n'

export default {
  computed: {
    ...mapGetters({
      notifications: 'notification/notifications'
    })
  },
  methods: {
    execAction(action, index) {
      if (action.action) {
        if (action.action === 'close') {
          this.$store.dispatch('notification/removeNotification', index)
        } else {
          action.action()
        }
      }
      this.$store.dispatch('notification/removeNotification', index)
    },
    mapNotificationMessage (notification) {
      if (typeof notification === "string" && notification.includes(`Not enough points in account`)) {
        return i18n.t(`You don't have sufficient points for this product`)
      }
      return notification
    }
  }
}
</script>

<style lang="scss" scoped>
$color-action: #ffffff;

.hiddenMessage {
  opacity: 0;
}

.notification {
  &:first-child {
    @apply mt-0;
  }
}

.actions {
  .notification-action {
    background: rgba($color-action, .2);

    &:hover {
      background: rgba($color-action, .3);
    }
  }

  .close {
    transform: translate(0, -50%);
  }
}

.success {
  @apply bg-success;
}

.hidden {
  opacity: 0;
  display: block !important;
  height: 1px !important;
}

.error {
  @apply bg-error;
}

.warning {
  @apply bg-warning;
}

.info {
  @apply bg-blue;
}
</style>
