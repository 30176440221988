<template>
  <div>
    <button class="md:hidden p-0 border-0 bg-transparent focus:outline-none z-loader" type="button" @click="toggleMenu">
      <span v-show="!isOpen">
        <i class="material-icons align-top">menu</i>
      </span>
      <span v-show="isOpen">
        <i class="material-icons align-top">close</i>
      </span>
    </button>
    <nav class="menu md:pl-2 md:flex" :class="{ 'is-open': isOpen }">
      <header-menu-blok v-if="blok" :item="blok.content"/>
    </nav>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import StoryblokSingle from 'theme/mixins/StoryblokSingle'
import HeaderMenuBlok from 'theme/components/storyblok/Header/HeaderMenu'
export default {
  name: 'HeaderMenu',
  data () {
    return {
      blokSlug: 'header-menu'
    }
  },
  components: {
    HeaderMenuBlok
  },
  mixins: [StoryblokSingle],
  computed: {
    ...mapState({
      isOpen: state => state.ui.mobilemenu,
      isMyAccountMenuOpen: state => state.ui.myaccountmenu
    })
  },
  methods: {
    toggleMenu() {
      if (this.isMyAccountMenuOpen) {
        this.$store.commit('ui/setMyAccountMenu', false)
      }
      this.$store.commit('ui/setMobileMenu', !this.isOpen)
      this.$store.commit('ui/setOverlay', this.isOpen)
    }
  }
}

</script>
<style lang="scss" scoped>
$screen-md: 768px;

@media screen and (max-width: #{$screen-md - 1}) {
  .menu {
    @apply fixed w-full border-none left-0 right-0 overflow-x-hidden overflow-y-scroll pt-13 z-modal;
    transition: top 1s cubic-bezier(0.190, 1.000, 0.220, 1.000), margin-top 1s cubic-bezier(0.190, 1.000, 0.220, 1.000);
    top: -120%;
    height: calc(100vh - 50px);
    margin-top: 50px;
    background-color: rgba(0, 0, 0, .9);

    &.is-open {
      @apply top-0;
    }
  }
}
</style>
