<template>
  <div class="default-layout" :class="{ 'pb-17': isFooterMenuOpen }">
    <overlay v-if="overlayActive" />
    <loader />
    <div id="viewport" class="w-full relative min-h-screen flex flex-col">
      <main-header v-show="!isCheckoutGroup && !isAuthGroup" class="print:hidden" />
      <checkout-header v-show="isCheckoutGroup" />
      <div :key="$route.path" v-if="isRestricted">
        <slot />
      </div>
      <main-footer v-show="!isAuthGroup && !isCartGroup && !isCheckoutGroup" class="mt-auto print:hidden" :class="{ 'statical': isFooterMenuOpen }" />
      <notification />
      <search-panel v-if="isSearchPanelOpen" />
      <whats-new v-if="currentUser"/>
      <vue-progress-bar/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CookieNotification from 'theme/components/core/CookieNotification'
import Notification from 'theme/components/core/Notification'
import MainHeader from 'theme/components/core/blocks/Header/Header'
import MainFooter from 'theme/components/core/blocks/Footer/Footer'
import CheckoutHeader from 'theme/components/core/blocks/Header/CheckoutHeader'
import Loader from 'theme/components/core/Loader'
import Overlay from 'theme/components/core/Overlay'
import Head from 'theme/head'
import { setTimeout, clearTimeout } from 'timers'
import { onlineHelper } from '@vue-storefront/core/helpers'
import WhatsNew from "theme/components/core/blocks/Versioning/WhatsNew";

const SearchPanel = () => import(/* webpackChunkName: "vsf-search-panel" */ 'theme/components/core/blocks/SearchPanel/SearchPanel')

export default {
  data () {
    return {
      headerCompact: false,
      isScrolling: false,
      loadingFailedTimer: null
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay,
      isSearchPanelOpen: state => state.ui.searchpanel,
      isSidebarOpen: state => state.ui.sidebar,
      isFooterMenuOpen: state => state.ui.footermenu,
      isWishlistOpen: state => state.ui.wishlist,
      currentUser: (state) => state.user.current
    }),
    isRestricted () {
      let publicRoutes = ['welcome', 'forgotpassword', 'createpassword', 'sign-in']
      return !this.currentUser || !publicRoutes.includes(this.$route.name)
    },
    currentRoute () {
      return this.$route.name
    },
    isCartGroup () {
      return (
        this.$route.name === 'rewards-cart' ||
        this.$route.name === 'cart'
      )
    },
    isCheckoutGroup () {
      return (
        this.$route.name === 'rewards-cart' ||
        this.$route.name === 'checkout' ||
        this.$route.name === 'checkout-success'
      )
    },
    isAuthGroup () {
      return this.$route.name === 'sign-in' || this.$route.name === 'forgotpassword' || this.$route.name === 'welcome'
    }
  },
  beforeMount () {
    this.$Progress.setColor('#00e1ba')

    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      this.$Progress.increase(40)

      if (this.loadingFailedTimer) {
        clearTimeout(this.loadingFailedTimer)
      }
      this.loadingFailedTimer = setTimeout(() => {
        if (!onlineHelper.isOnline) return

        if (to.fullPath === from.fullPath) {
          window.location.reload(true)
        } else {
          window.location.href = to.fullPath
        }
      }, 5000)

      next()
    })

    this.$router.beforeResolve((to, from, next) => {
      if (this.loadingFailedTimer) {
        clearTimeout(this.loadingFailedTimer)
      }

      next()
    })

    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
      this.$Progress.setColor('#00e1ba')
    })

    window.addEventListener('scroll', () => {
      this.isScrolling = true
    }, { passive: true })

    setInterval(() => {
      if (this.isScrolling) {
        this.hasScrolled()
        this.isScrolling = false
      }
    }, 250)
  },
  methods: {
    hasScrolled () {
      this.headerCompact = window.scrollY > 10
    }
  },
  metaInfo: Head,
  components: {
    WhatsNew,
    MainHeader,
    MainFooter,
    CheckoutHeader,
    Loader,
    SearchPanel,
    CookieNotification,
    Notification,
    Overlay
  }
}
</script>

<style lang="scss" src="theme/css/main.scss"></style>
