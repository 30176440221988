<template>
  <footer class="main-footer relative w-full bg-black text-white py-md lg:py-0 lg:pt-11 overflow-visible" role="contentinfo">
    <div class="container h-full mx-auto flex flex-wrap">
      <FooterMobileMenu class="w-1/5 lg:hidden" />
      <div class="hidden lg:flex w-full mb-4">
        <div class="w-1/5">
          <h3 class="font-sans font-bold text-base leading-normal uppercase text-center block">
            <router-link
              :to="localizedRoute('/')"
              :title="$t('Luxury Brand Partners')"
              class="footer-logo relative text-white font-bold no-underline"
            >
              Luxury Brand <br>Partners
            </router-link>
          </h3>
        </div>
        <div
          :key="link.id"
          v-for="link in FooterLinks"
          class="w-1/5"
        >
          <h4 class="uppercase block mb-5 font-sans text-sm font-normal tracking-2xl leading-none subpixel-antialiased">{{ link.name }}</h4>
          <ul class="unstyled" v-if="link.subMenu">
            <li
            :key="sublink.id"
            v-for="sublink in link.subMenu.links">
              <router-link
                v-if="!sublink.external"
                :to="localizedRoute(sublink.url)"
                :title="sublink.name"
                class="text-white text-average font-normal subpixel-antialiased"
              >
                <span>{{ sublink.name }}</span>
              </router-link>
              <a v-else class="text-white text-average font-normal subpixel-antialiased" :href="sublink.url">
                <span>{{ sublink.name }}</span>
              </a>
            </li>
          </ul>
        </div>
<!--         <div class="w-1/5">
          <h4 class="uppercase block mb-5 font-sans text-sm font-normal tracking-2xl leading-none subpixel-antialiased">Subscribe</h4>
          <NewsletterInline />
        </div> -->
      </div>
      <div class="copyright lg:relative absolute text-white text-sm lg:text-xs tracking-mid lg:tracking-md leading-loose lg:leading-sm lg:pb-md uppercase text-center lg:text-right w-full flex flex-col justify-center lg:block inset-0 px-0 z-loader lg:z-auto bg-black">
        <span class="block lg:inline-block">&copy; {{ currentYear }} {{ $t('Luxury Brand Partners.') }}</span>
        <span class="block lg:inline-block">{{ $t('All Rights Reserved.') }}</span>
        <span class="block cursor-pointer" @click="openReleaseNotes">{{ $t('Ver') }} {{ version }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
// import NewsletterInline from 'theme/components/core/NewsletterInline'
import FooterMobileMenu from 'theme/components/core/blocks/Footer/FooterMobileMenu'
import FooterLinks from 'theme/components/core/blocks/Footer/FooterLinks'

export default {
  components: {
    FooterMobileMenu
  },
  data () {
    return {
      version: this.$store.state.version,
      FooterLinks: FooterLinks,
      currentYear: new Date().getFullYear()
    }
  },
  methods: {
    openReleaseNotes () {
      this.$bus.$emit('modal-show', 'whats-new')
    }
  }
}
</script>

<style lang="scss" scoped>
.main-footer {
  .footer-logo {
    left: -26px;
  }

  &.statical {
    @apply fixed left-0 right-0 bottom-0 z-50;
  }
}

@media print {
  .main-footer {
    display: none !important;
  }
}
</style>
