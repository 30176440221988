<template>
  <div class="version-update" v-show="isVisible" @click="update">
    <div class="container">
      LBP Pro <strong>v{{ version }}</strong> has been released. &#127881; Please click here to upgrade.
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import i18n from '@vue-storefront/i18n'

export default {
  name: 'Update',
  computed: {
    ...mapState({
      version: state => state.versioning.version,
      isVisible: state => state.versioning.readyToUpdate
    })
  },
  mounted () {
    if (!this.$isServer && window) {
      let version = window.localStorage.getItem('lbppro/version-upgrade')

      try {
        window.localStorage.removeItem('lbppro/version-upgrade')
      } catch (e) {
        console.log(e)
      }

      if (version) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'success',
          message: i18n.t('You are now on version ' + version),
          action1: { label: i18n.t('OK') }
        })
      }
    }
  },
  methods: {
    update () {
      try {
        window.localStorage.setItem('lbppro/version-upgrade', this.version)
      } catch (e) {
        console.log(e)
      }

      this.$store.dispatch('versioning/update', true)
    }
  }
}
</script>

<style scoped>
  .version-update {
    @apply bg-green text-black py-2 text-center cursor-pointer;
  }
</style>
