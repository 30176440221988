<template>
  <div class="loader-container fixed bg-overlayBg z-modal inset-0 opacity-100" v-if="isVisible">
    <div class="loader-inner-container fixed">
      <div class="spinner relative">
        <div class="double-bounce1 absolute w-100 brdr-circle bg-cl-th-success" />
        <div class="double-bounce2 absolute w-100 brdr-circle bg-cl-th-success" />
      </div>
      <div
        class="loader-message-container mt15 py5 px15 align-center h6 cl-white"
        v-if="message"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Loader',
  data () {
    return {
      message: null
    }
  },
  methods: {
    show (message = null) {
      this.message = message
      this.$store.commit('ui/setLoader', true)
    },
    hide () {
      this.$store.commit('ui/setLoader', false)
    }
  },
  computed: mapState({
    isVisible: state => state.ui.loader
  }),
  beforeMount () {
    this.$bus.$on('notification-progress-start', this.show)
    this.$bus.$on('notification-progress-stop', this.hide)
  },
  beforeDestroy () {
    this.$bus.$off('notification-progress-start', this.show)
    this.$bus.$off('notification-progress-stop', this.hide)
  }
}
</script>
<style lang="scss" scoped>

.loader-container {
  background-color: rgba(255,255,255,0.95);
  background-image: url('/assets/loading.gif');
  background-repeat: no-repeat;
  background-position: center 200px;
  backface-visibility: hidden;
  transition: opacity 0.5s ease, top 0s ease 0.5s, left 0s ease 0.5s, background-position 0.25s ease;
}

.loader-inner-container {
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

</style>
