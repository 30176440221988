<template>
  <transition name="fade" appear>
    <div v-if="isOpen" class="fixed bottom-0 w-full bg-primary text-white" role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc">
      <div class="flex items-center px-8 py-4">
        <span id="cookieconsent:desc">
          {{ message }}
          <router-link :to="localizedRoute('detailsLink')" :title="detailsLinkText" class="underline hover:no-underline">
            {{ detailsLinkText }}
          </router-link>
        </span>

        <div class="ml-auto">
          <a
            href="javascript:void(0);"
            class="accept-button block px-3 py-1 border-2 border-white font-bold text-center"
            @click="accept"
            @keyup.enter="accept"
          >
            {{ $t('Got it!') }}
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import i18n from '@vue-storefront/i18n'

export default {
  props: {
    detailsLinkText: {
      type: String,
      default: i18n.t('Learn more')
    },
    detailsLink: {
      type: String,
      default: '/privacy-policy'
    },
    message: {
      type: String,
      default: i18n.t('This website uses cookies to ensure you get the best experience on our website.')
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    accept () {
      this.setVisited()
      this.isOpen = false
    },
    setVisited () {
      this.$store.dispatch('claims/set', { claimCode: 'cookiesAccepted', value: true })
    }
  },
  created () {
    this.$store.dispatch('claims/check', { claimCode: 'cookiesAccepted' }).then((cookieClaim) => {
      if (!cookieClaim) {
        this.isOpen = true
        this.$store.dispatch('claims/set', { claimCode: 'cookiesAccepted', value: false })
      } else {
        this.isOpen = !cookieClaim.value
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.accept-button {
  min-width: 140px;
}
</style>
