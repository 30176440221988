export const MicrocartButton = {
  name: 'MicrocartButton',
  mounted () {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.$store.dispatch('cart/load')
      }
    })

    if (!this.$isServer && !this.$store.state.cart.cartIsLoaded) {
      let intv = window.setInterval(() => {
        this.$store.dispatch('cart/load')
        window.clearInterval(intv)
      }, 2000)
    }
  },
  methods: {
    toggleMicrocart () {
      this.$store.dispatch('cart/toggleMicrocart')
    }
  },
  computed: {
    quantity () {
      return this.$store.getters['cart/getItemsTotalQuantity']
    }
  }
}
