<template>
  <ul class="list-none p-0 m-0 md:flex md:flex-row text-center md:text-left">
    <sb-render :key="_item._uid" v-for="_item in item.links" :item="_item" />
  </ul>
</template>
<script>
import {
  Blok
} from 'src/modules/vsf-storyblok-module/components'
export default {
  extends: Blok,
  name: 'HeaderMenu'
}

</script>
