<template>
  <li
    class="relative leading-loose"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
    data-testid="openMicrocart">
    <router-link
      :aria-label="$t('Cart')"
      class="relative flex justify-center items-center focus:outline-none pl-2 md:pl-md xl:px-sm z-loader xl:hover:bg-grey-30"
      :to="localizedRoute('/cart')"
    >
      <i class="ss-icon ss-cart text-heading-page xl:text-mid leading-loose"></i>
    </router-link>
    <Microcart v-show="isMicrocartOpen" />
  </li>
</template>

<script>
import { mapState } from 'vuex'
import { MicrocartButton } from 'theme/components/core/blocks/Header/MicrocartButton'

const Microcart = () => import(/* webpackChunkName: "vsf-microcart" */ 'theme/components/core/blocks/Microcart/Microcart')

export default {
  mixins: [MicrocartButton],
  components: {
    Microcart
  },
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    totalQuantity () {
      // Data field renamed to 'quantity'
      return this.quantity
    }
  },
  methods: {
    openMicrocart () {
      // Method renamed to 'toggleMicrocart' and is using cart store now
      this.$store.dispatch('ui/toggleMicrocart')
    },
    onMouseOver () {
      if (!this.isMicrocartOpen) this.openMicrocart()
    },
    onMouseOut () {
      if (this.isMicrocartOpen) this.$store.dispatch('ui/toggleMicrocart')
    }
  }
}
</script>
