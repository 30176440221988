<template>
  <modal class="whats-new bg-center bg-no-repeat bg-cover" name="whats-new">
    <h2 class="uppercase text-h2">What's New</h2>
    <div v-for="item in getItems" :key="item._uid" class="item relative" :class="{current: item.version === version }">
      <div class="badge">Latest Release</div>
      <h3 class="text-h3">Release {{ item.version }} &raquo; {{ item.title }}</h3>
      <div class="content">
        <wysiwyg :item="item.content"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from 'theme/components/core/Modal'
import StoryblokSingle from 'theme/mixins/StoryblokSingle'
import Wysiwyg from 'theme/components/storyblok/Blocks/Wysiwyg'
import {SET_MODAL, SN_VERSIONING} from "theme/modules/versioning/store/mutation-types";

export default {
  name: 'WhatsNew',
  components: {Wysiwyg, Modal},
  mixins: [StoryblokSingle],
  data () {
    return {
      blokSlug: 'whats-new',
      version: this.$store.state.versioning.version
    }
  },
  mounted () {
    if (this.$store.state.versioning.modal) {
      this.$bus.$emit('modal-show', 'whats-new')
      this.$store.commit(`${SN_VERSIONING}/` + SET_MODAL, false)
      this.$db.versioningCollection.setItem('modal', false)
    }
  },
  computed: {
    getLatest () {
      if (this.getItem && this.getItem.length) {
        return this.getItem.find(x => x.version === this.version)
      }
      return false
    },
    getItems () {
      return this.blok && this.blok.content ? this.blok.content.items : []
    }
  }
}
</script>

<style scoped>
  .item {
    @apply mt-5 pt-2 pb-4 px-4 border
  }
  .item.current {
    @apply border border-solid border-green border-6
  }

  li::v-deep {
    @apply text-above-md
  }

  .badge {
    display:none;
  }

  .current .badge {
    display: block;
    position: absolute;
    right: 1rem;
    top: -1rem;
    text-transform: uppercase;
    background: black;
    padding: 0 0.5rem;
    color: white;
  }
</style>
