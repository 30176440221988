<template>
  <modal name="modal-return-to-cart" class="modal modal-return-to-cart">
    <div slot="header">
      <div class="text-center uppercase tracking-xl leading-h2 text-h5 font-bold">
        {{ $t('LOYALTY PROGRAM') }}
      </div>
    </div>
    <div class="pb-16" slot="content">
      <div class="popup-title font-black tracking-lg leading-none uppercase text-center text-black">
        {{ $t('REDEEM YOUR POINTS') }}
      </div>
      <div class="popup-content mt-5 mb-md">
        <div class="text-black heading-page leading-sm">
          {{ $t('If you return to cart you will lose the redemption products in your cart and have to start the loyalty point redemption process over. We recommend this action only if you wish to add more retail products to your order to earn more points to spend in the redemption cart. To complete your order without wiping out the NFR products in your redemption cart, please continue to checkout.') }}
        </div>
      </div>
      <div class="redeem-actions sm:flex sm:justify-between">
        <button-full class="w-full sm:w-1/2 popup-button flex-grow mt-5 sm:mt-0 sm:mr-3.5 pr-5 pl-10" @click.native="goToCart()">
          <span>{{ $t('Edit cart') }}</span>
          <i class="ss-gizmo checkout-icon ss-right text-h5"></i>
        </button-full>
        <button-full
          class="w-full sm:w-1/2 popup-button flex-grow mt-5 sm:mt-0 sm:ml-3.5 pr-5 pl-10"
          @click.native="goToCheckout()"
        >
          <span>{{ $t('Continue to checkout') }}</span>
          <i class="ss-gizmo checkout-icon ss-right text-h5"></i>
        </button-full>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from 'theme/components/core/Modal'
import ButtonFull from 'theme/components/theme/ButtonFull'

export default {
  components: {
    Modal,
    ButtonFull
  },
  methods: {
    goToCart () {
      this.$store.dispatch('cart/cartPull', { update: false }).then(() => {
        this.$router.push({ name: 'cart' })
        this.$bus.$emit('modal-hide', 'modal-return-to-cart')
      }).catch(err => {
        console.error(err)
        this.$router.push({ name: 'cart' })
        this.$bus.$emit('modal-hide', 'modal-return-to-cart')
      })
    },
    goToCheckout () {
      this.$router.push({ name: 'checkout' })
      this.$bus.$emit('modal-hide', 'modal-return-to-cart')
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-return-to-cart {
    &::v-deep {
      .modal-container-wrapper {
        @apply w-full p-5;

        @screen md {
          @apply p-0;
        }
      }

      .modal-container {
        @apply w-full;

        @screen md {
          @apply mx-auto;
          width: 660px;
        }
      }
    }
  }

  .popup-title {
    font-size: 50px;
    margin-bottom: 7px;
  }

  .popup-button {
    padding-bottom: 18px;
    padding-top: 18px;

    &::v-deep {
      span {
        @apply flex justify-between items-center;
      }
    }
  }
</style>
