<template>
  <div>
    <button class="button lg:hidden p-0 border-0 bg-transparent focus:outline-none h-full z-uploader lg:z-auto relative" type="button" @click="toggleMenu">
      <span v-show="!isOpen">
        <i class="material-icons block">menu</i>
      </span>
      <span v-show="isOpen">
        <i class="material-icons block">close</i>
      </span>
    </button>
    <nav class="menu w-full fixed border-none left-0 right-0 overflow-x-hidden overflow-y-scroll pt-13 px-5 flex flex-col" :class="{ 'is-open': isOpen }">
      <ul class="list-none p-0 m-0">
        <li class="mb-13">
          <h3 class="font-sans text-bold text-average leading-base uppercase text-center block">
            <router-link
              :to="localizedRoute('/')"
              :title="$t('Luxury Brand Partners')"
              class="text-white font-bold no-underline"
            >
              Luxury Brand <br>Partners
            </router-link>
          </h3>
        </li>
        <li
          :key="link.id"
          v-for="(link, index) in FooterLinks"
          class="item w-full relative m-0 p-0"
          @click.prevent="toggleSubMenu(index, link.subMenu)"
        >
          <router-link
            class="flex flex-1 text-above-md font-normal leading-normal tracking-above-lg text-white uppercase px-7 py-md items-center justify-center"
            :to="localizedRoute(link.url)"
          >
            <span>{{ link.name }}</span>
            <i class="ss-icon leading-loose ml-sm transition-all transition-delay-100 transition-ease-in duration-75" v-if="link.subMenu" :class="{'ss-navigateup': index === activeIndex, 'ss-navigatedown': index !== activeIndex}"></i>
          </router-link>
          <transition name="slide-down" :duration="{ enter: 700, leave: 2000 }">
            <ul class="sub-menu relative p-0 text-center" v-if="link.subMenu && index === activeIndex">
              <li
              :key="sublink.id"
              v-for="sublink in link.subMenu.links"
              class="block relative m-0">
                <router-link
                  v-if="!sublink.external"
                  class="block text-average text-white font-normal uppercase leading-loose tracking-lg py-xs"
                  :to="localizedRoute(sublink.url)"
                >
                  <span>{{ sublink.name }}</span>
                </router-link>
                <a v-else
                   class="block text-average text-white font-normal uppercase leading-loose tracking-lg py-xs"
                   :href="sublink.url">
                  <span>{{ sublink.name }}</span>
                </a>
              </li>
            </ul>
          </transition>
        </li>
       <!--  <li class="container my-lg"><NewsletterInline /></li> -->
      </ul>
    </nav>
  </div>
</template>

<script>
// import NewsletterInline from 'theme/components/core/NewsletterInline'
import FooterLinks from 'theme/components/core/blocks/Footer/FooterLinks'

export default {
  name: 'FooterMobileMenu',
  data () {
    return {
      footerSelector: '.main-footer',
      activeIndex: null,
      isOpen: false,
      FooterLinks: FooterLinks
    }
  },
  methods: {
    openMenu () {
      this.isOpen = true
    },
    closeMenu () {
      this.isOpen = false
    },
    toggleMenu () {
      this.isOpen = !this.isOpen
      if (this.isOpen === false) {
        this.$store.commit('ui/setOverlay', false)
        this.$store.commit('ui/setFooterMenu', false)
      } else {
        this.$store.commit('ui/setOverlay', true)
        this.$store.commit('ui/setFooterMenu', true)
      }
    },
    toggleSubMenu (index, subMenu) {
      if (subMenu) {
        return this.activeIndex === index ?
          this.activeIndex = null :
          this.activeIndex = index
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$screen-xl: 1124px;
@media screen and (max-width: #{$screen-xl - 1}) {
  .slide-down-enter-active {
    transition: all 0.7s ease-in;
  }

  .slide-down-leave-active {
    transition: all 0.7s cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-down-enter-to,
  .slide-down-leave {
    max-height: 400px;
    overflow: hidden;
  }

  .slide-down-enter,
  .slide-down-leave-to {
    overflow: hidden;
    max-height: 0;
  }
  .menu {
    @apply z-modal;
    transition: bottom 1s cubic-bezier(0.190, 1.000, 0.220, 1.000);
    bottom: -100%;
    height: calc(100% - 60px);
    background-color: rgba(0, 0, 0, .9);
    &.is-open {
      bottom: 60px;
    }
  }
}

</style>
