<template>
  <router-link
    role="button"
    :aria-label="$t('Open search panel')"
    class="flex justify-center items-center focus:outline-none xl:px-sm md:px-md px-2 xl:hover:bg-grey-30"
    :to="localizedRoute('/catalogsearch/advanced')"
  >
    <i class="ss-icon ss-search text-heading-page xl:text-mid leading-loose"></i>
  </router-link>
</template>

<script>
import SearchIcon from '@vue-storefront/core/compatibility/components/blocks/Header/SearchIcon'

export default {
  mixins: [SearchIcon]
}
</script>
