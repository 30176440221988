<template>
  <li class="relative account-list" @mouseleave="isMobile ? null : closeSubMenu()">
    <button type="button" data-testid="accountButton" @click.prevent="toggleMenu()" class="relative flex justify-center items-center focus:outline-none xl:px-sm text-left z-loader"
      :aria-label="$t('Open my account')" :class="{ 'bg-grey-30': showMenu && !isMobile }" @mouseenter="isMobile ? null : openSubMenu()">
      <span class="font-serif text-base text-white xl:font-bold leading-loose tracking-tiny italic">
        {{ user ? (user.prefix ? user.prefix : ([user.firstname, user.lastname].join(' '))) : 'My Account' }}
      </span>
      <i class="ss-icon text-h5 leading-h5 ml-xs mt-1 ss-navigatedown account-menu__icon" :class="{'account-menu__icon--active': showMenu && isMobile }"></i>
    </button>
    <no-ssr>
      <transition name="slide-down">
        <div class="loyalty"
          v-show="showMenu">
          <div class="flex justify-end sub-menu">
            <div class="w-full xl:w-2/3">
              <earned-points-balance />
              <div class="clearfix"></div>
              <div class="flex">
<!--                <router-link :to="localizedRoute({ name: 'cart' })" class="redeem-points text-sm leading-none tracking-lg uppercase font-bold mt-md p-0 py-7.5 inline-block w-full xl:w-1/2"><span>Redeem-->
<!--                    your points</span><i class="ss-icon ss-right text-sm ml-xs"></i></router-link>-->
                <router-link :to="localizedRoute('/rewards-program')" class="learn-about-rewards text-sm leading-none tracking-lg uppercase font-bold mt-md p-0 py-7.5 inline-block hidden xl:block xl:w-1/2"><span>Learn
                    About LBP Rewards</span><i class="ss-icon ss-right text-sm ml-xs"></i></router-link>
              </div>
            </div>
            <div class="account-links w-full xl:w-1/3 text-center xl:text-right">
              <div v-for="link in AccountLinks" :key="link.id" class="block relative m-0 mb-xs py-xs leading-sm">
                <router-link class="flex text-h5 text-grey-15 font-bold uppercase leading-loose tracking-lg hover:text-white items-center"
                  :to="localizedRoute(link.url)">
                  <span class="flex-1">{{ link.name }}</span>
                </router-link>
              </div>
              <div class="block relative m-0 my-xs py-sm leading-sm" key="logout">
                <a href="javascript:void(0);" class="flex text-h5 text-grey-15 font-bold uppercase leading-loose tracking-lg hover:text-white items-center"
                  @click.prevent="logout">
                  <span class="flex-1">{{ $t('Sign Out') }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </no-ssr>
  </li>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { mapState, mapGetters } from 'vuex'
import EarnedPointsBalance from 'theme/components/theme/EarnedPointsBalance'
import AccountIcon from '@vue-storefront/core/compatibility/components/blocks/Header/AccountIcon'
import i18n from '@vue-storefront/i18n'

export default {
  name: 'AccountMenu',
  mixins: [AccountIcon],
  components: {
    'no-ssr': NoSSR,
    EarnedPointsBalance
  },
  data () {
    return {
      desktopPoint: 1124,
      isMobile: false,
      AccountLinks: [{
        id: 1,
        name: i18n.t('Account Info'),
        url: '/customer/account'
      },
      {
        id: 2,
        name: i18n.t('Quick Order'),
        url: '/quick-order'
      },
      {
        id: 3,
        name: i18n.t('Stored Credit Cards'),
        url: '/storedpayments/customer/payments/'
      },
      {
        id: 4,
        name: i18n.t('Orders'),
        url: '/sales/order/history/'
      },
      {
        id: 5,
        name: i18n.t('Rewards'),
        url: '/customer/rewards/'
      },
      {
        id: 8,
        name: i18n.t('Address Book'),
        url: '/customer/address/'
      }]
    }
  },
  computed: {
    ...mapState({
      isMobileMenuOpen: state => state.ui.mobilemenu,
      showMenu: state => state.ui.myaccountmenu,
      user: state => state.user.current
    }),
    ...mapGetters({
      rewards: 'loyalty/getCustomerAccounts'
    })
  },
  watch: {
    $route: {
      handler () {
        this.$store.commit('ui/setMyAccountMenu', false)
        this.$store.commit('ui/setOverlay', false)
        this.$store.commit('ui/setMobileMenu', false)
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkIsMobile)
  },
  mounted: function () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.checkIsMobile)
      this.checkIsMobile()
    })
  },
  methods: {
    toggleMenu () {
      if (this.isMobileMenuOpen) {
        this.$store.commit('ui/setMobileMenu', false)
      }
      this.$store.commit('ui/setMyAccountMenu', !this.showMenu)
      this.$store.commit('ui/setOverlay', this.showMenu)
      if (this.showMenu) {
        this.$bus.$emit('refresh-loyalty-points')
      }
    },
    closeSubMenu () {
      this.$store.commit('ui/setMyAccountMenu', false)
    },
    openSubMenu () {
      this.$store.commit('ui/setMyAccountMenu', true)
      if (this.showMenu) {
        this.$bus.$emit('refresh-loyalty-points')
      }
    },
    checkIsMobile () {
      this.windowWidth = document.documentElement.clientWidth
      this.isMobile = this.windowWidth <= this.desktopPoint
    },
    logout () {
      this.$bus.$emit('user-before-logout')
      this.$router.push(this.localizedRoute('/welcome'))
    }
  }
}

</script>

<style lang="scss">
.slide-down-enter-active {
  transition: top 1s cubic-bezier(0.190, 1.000, 0.220, 1.000);

  @screen xl {
    transition: opacity 0.25s ease;
  }
}

.slide-down-leave-active {
  transition: top 1s cubic-bezier(0.190, 1.000, 0.220, 1.000);

  @screen xl {
    transition: opacity 0.25s ease;
  }
}

.slide-down-enter-to,
.slide-down-leave {
  @screen xl {
    opacity: 1;
  }
}

.slide-down-enter,
.slide-down-leave-to {
  @screen xl {
    opacity: 0;
  }
}

.loyalty {
  @apply absolute right-0 top-full border-10 border-grey-30 bg-black px-xl py-5 font-sans;
  width: 725px;
  height: auto;

  @screen lg {
    @apply p-5;
  }

  .loyalty-points {
    display: inline-block;
    letter-spacing: 0;
    color: #FFF;

    i {
      font: italic normal 400 18px / 20px 'freight-big-pro', Times, 'Times New Roman', Georgia, serif;
    }

    span {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      font-style: inherit;
    }

    &:after {
      display: table;
      clear: both;
      content: '';
    }

    .loyalty-header {
      position: relative;
      width: 100%;

      &:after {
        display: table;
        clear: both;
        content: '';
      }

      div {
        position: relative;
        display: block;
        float: left;
        height: 34px;
        color: #FFF;

        span {
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          transform: translate(0, -50%);
        }

        &:nth-child(1) {
          font: italic normal 400 16px/20px 'freight-big-pro', Times, 'Times New Roman', Georgia, serif;
          width: 166px;
          color: #8F7B4C;

          span {
            font-size: inherit;
          }
        }

        &:nth-child(2) {
          font: normal normal 400 9px/11px 'brandon-grotesque', Arial, 'Helvetica Neue', Helvetica, sans-serif;
          width: 117px;
          text-indent: 24px;
          text-transform: uppercase;

          span {
            font-size: inherit;
          }
        }

        &:nth-child(3) {
          font: normal normal 400 9px/11px 'brandon-grotesque', Arial, 'Helvetica Neue', Helvetica, sans-serif;
          width: 88px;
          text-indent: 14px;
          text-transform: uppercase;

          span {
            font-size: inherit;
          }
        }
      }
    }
  }

  .loyalty-brand {
    position: relative;
    float: left;
    width: auto;
    border-left: 1px solid #808080;

    &:after {
      display: table;
      clear: both;
      content: '';
    }

    &:last-child {
      border-bottom: 1px solid rgba(128, 128, 128, .43);
    }

    .brand-name {
      font: normal normal 700 12px/normal 'brandon-grotesque', Arial, 'Helvetica Neue', Helvetica, sans-serif;
      position: relative;
      z-index: 5;
      display: block;
      float: left;
      width: 164px;
      border-top: 1px solid #808080;

      span {
        position: absolute;
        top: 9.5px;
        right: 20px;
        text-transform: uppercase;
        color: #FFF;
      }
    }

    .points-rewards {
      z-index: 3;
      float: right;
      width: 210px;
      border-top: 1px solid rgba(128, 128, 128, .43);
      border-right: 1px solid rgba(128, 128, 128, .43);
      border-left: 1px solid rgba(128, 128, 128, .43);

      .reward-block {
        font: normal normal 400 13px/normal 'brandon-grotesque', Arial, 'Helvetica Neue', Helvetica, sans-serif;
        position: relative;
        z-index: 10;
        color: #FFF;
        border-bottom: 1px solid rgba(128, 128, 128, .43);

        &:last-child {
          border-bottom: none;
        }

        &:after {
          display: table;
          clear: both;
          content: '';
        }

        .reward-amount {
          position: relative;
          display: block;
          float: right;
          text-indent: 24px;
          padding: 9px 40px;
        }

        .reward-hover-overlay {
          position: absolute;
          top: 0;
          right: 0;
          padding: 10px 12px;
          transition: all .3s linear 0s;
          transform: translate(93%, -85%);
          pointer-events: none;
          opacity: 0;
          color: #000;
          background-color: #FFF;
          box-shadow: 2px 4px 19px rgba(0, 0, 0, .3);

          .point-value {
            font: normal normal 400 16px/30px 'brandon-grotesque', Arial, 'Helvetica Neue', Helvetica, sans-serif;
            text-transform: uppercase;
          }

          .point-type {
            font-size: 14px;
            line-height: normal;
            max-width: 180px;

            span {
              font-weight: bold;
            }
          }
        }

        &:hover .reward-hover-overlay {
          transform: translate(93%, -75%);
          opacity: 1;
        }
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 164px;
        height: 100%;
        content: '';
        background-color: #000;
      }
    }

    &[data-brand='rco'],
    &[data-brand='14'],
    &.brand-id-14 {
      border-left: 1px solid #00E1BA;
    }

    &[data-brand='rco'] .brand-name,
    &[data-brand='14'] .brand-name,
    &.brand-id-14 .brand-name {
      border-top: 1px solid #00E1BA;
    }

    &[data-brand='rco'] .brand-name span,
    &[data-brand='14'] .brand-name span,
    &.brand-id-14 .brand-name span {
      color: #00E1BA;
    }

    &[data-brand='rco'] .points-rewards,
    &[data-brand='14'] .points-rewards,
    &.brand-id-14 .points-rewards {
      border-left: 1px solid #00E1BA;
    }

    &[data-brand='rco'] .points-rewards .reward-block .reward-hover-overlay .point-value,
    &[data-brand='14'] .points-rewards .reward-block .reward-hover-overlay .point-value,
    &.brand-id-14 .points-rewards .reward-block .reward-hover-overlay .point-value {
      color: #00E1BA;
    }

    &[data-brand='v76'],
    &[data-brand='13'],
    &.brand-id-13 {
      border-left: 1px solid #F3F281;
    }

    &[data-brand='v76'] .brand-name,
    &[data-brand='13'] .brand-name,
    &.brand-id-13 .brand-name {
      border-top: 1px solid #F3F281;
    }

    &[data-brand='v76'] .brand-name span,
    &[data-brand='13'] .brand-name span,
    &.brand-id-13 .brand-name span {
      color: #F3F281;
    }

    &[data-brand='v76'] .points-rewards,
    &[data-brand='13'] .points-rewards,
    &.brand-id-13 .points-rewards {
      border-left: 1px solid #F3F281;
    }

    &[data-brand='v76'] .points-rewards .reward-block .reward-amount.up:after,
    &[data-brand='13'] .points-rewards .reward-block .reward-amount.up:after,
    &.brand-id-13 .points-rewards .reward-block .reward-amount.up:after {
      border-bottom: 5px solid #F3F281;
    }

    &[data-brand='v76'] .points-rewards .reward-block .reward-hover-overlay .point-value,
    &[data-brand='13'] .points-rewards .reward-block .reward-hover-overlay .point-value,
    &.brand-id-13 .points-rewards .reward-block .reward-hover-overlay .point-value {
      color: #F3F281;
    }

    &[data-brand='sc'],
    &[data-brand='74'],
    &.brand-id-74 {
      border-left: 1px solid #F84876;
    }

    &[data-brand='sc'] .brand-name,
    &[data-brand='74'] .brand-name,
    &.brand-id-74 .brand-name {
      border-top: 1px solid #F84876;
    }

    &[data-brand='sc'] .brand-name span,
    &[data-brand='74'] .brand-name span,
    &.brand-id-74 .brand-name span {
      color: #F84876;
    }

    &[data-brand='sc'] .points-rewards,
    &[data-brand='74'] .points-rewards,
    &.brand-id-74 .points-rewards {
      border-left: 1px solid #F84876;
    }

    &[data-brand='sc'] .points-rewards .reward-block .reward-amount.up:after,
    &[data-brand='74'] .points-rewards .reward-block .reward-amount.up:after,
    &.brand-id-74 .points-rewards .reward-block .reward-amount.up:after {
      border-bottom: 5px solid #F84876;
    }

    &[data-brand='sc'] .points-rewards .reward-block .reward-hover-overlay .point-value,
    &[data-brand='74'] .points-rewards .reward-block .reward-hover-overlay .point-value,
    &.brand-id-74 .points-rewards .reward-block .reward-hover-overlay .point-value {
      color: #F84876;
    }

    &[data-brand='lbp-corporate'],
    &[data-brand='85'],
    &.brand-id-85 {
      border-left: 1px solid #555467;
    }

    &[data-brand='lbp-corporate'] .brand-name,
    &[data-brand='85'] .brand-name,
    &.brand-id-85 .brand-name {
      border-top: 1px solid #555467;
    }

    &[data-brand='lbp-corporate'] .brand-name span,
    &[data-brand='85'] .brand-name span,
    &.brand-id-85 .brand-name span {
      color: #555467;
    }

    &[data-brand='lbp-corporate'] .points-rewards,
    &[data-brand='85'] .points-rewards,
    &.brand-id-85 .points-rewards {
      border-left: 1px solid #555467;
    }

    &[data-brand='lbp-corporate'] .points-rewards .reward-block .reward-amount.up:after,
    &[data-brand='85'] .points-rewards .reward-block .reward-amount.up:after,
    &.brand-id-85 .points-rewards .reward-block .reward-amount.up:after {
      border-bottom: 5px solid #555467;
    }

    &[data-brand='lbp-corporate'] .points-rewards .reward-block .reward-hover-overlay .point-value,
    &[data-brand='85'] .points-rewards .reward-block .reward-hover-overlay .point-value,
    &.brand-id-85 .points-rewards .reward-block .reward-hover-overlay .point-value {
      color: #555467;
    }
  }
}

@media screen and (max-width: theme('screens.xl')) {
  .sub-menu {
    @apply flex-col-reverse;
  }

  .loyalty {
    @apply fixed w-full border-none left-0 right-0 overflow-x-hidden overflow-y-scroll mt-13 z-modal top-0;
    height: calc(100vh - 50px);
    background-color: rgba(0, 0, 0, .9);

    &__icon {
      &--active {
        &:before {
          content: '\F500';
        }
      }
    }

    .account-links {
      li {
        @apply py-0;

        a {
          @apply py-xs text-average font-normal;
        }
      }
    }

    .loyalty-points {
      @apply block;
      width: 90%;
      margin: 0 auto;

      .loyalty-header {
        div {
          display: none;
        }

        >div:first-child {
          display: block;
          width: 100%;
          text-align: center;
          @apply py-xl;

          span {
            width: 100%;
          }
        }
      }

      .loyalty-brand {
        .brand-name span {
          color: #FFF !important;
        }

        .points-rewards .reward-block {
          color: #000;
          background-color: #F9F9F9;

          .reward-amount {
            color: #8F7B4C;
          }
        }

        &:nth-of-type(2n+1) .points-rewards .reward-block {
          background-color: #FFF;
        }

        &[data-brand='rco'] {
          .points-rewards {
            .reward-block .reward-amount.up:after {
              border-bottom: 5px solid #00E1BA;
            }

            &:after {
              background-color: #00E1BA;
            }
          }

          .brand-name span {
            color: #000 !important;
          }
        }

        &[data-brand='v76'] {
          .points-rewards {
            .reward-block .reward-amount.up:after {
              border-bottom: 5px solid #F3F281;
            }

            &:after {
              background-color: #F3F281;
            }
          }

          .brand-name span {
            color: #000 !important;
          }
        }

        &[data-brand='sc'] .points-rewards {
          .reward-block .reward-amount.up:after {
            border-bottom: 5px solid #F84876;
          }

          &:after {
            background-color: #F84876;
          }
        }

        &[data-brand='lbp-corporate'] .points-rewards {
          .reward-block .reward-amount.up:after {
            border-bottom: 5px solid #555467;
          }

          &:after {
            background-color: #555467;
          }
        }
      }
    }

    .loyalty-brand {
      position: relative;
      float: left;
      box-sizing: border-box;
      width: auto;
      width: 100%;
      border-left: 1px solid #808080;

      &:after {
        display: table;
        clear: both;
        content: '';
      }

      .brand-name {
        font: normal normal 700 12px/normal 'brandon-grotesque', Arial, 'Helvetica Neue', Helvetica, sans-serif;
        position: relative;
        z-index: 5;
        display: block;
        float: left;
        box-sizing: border-box;
        width: 50%;
        border-top: 1px solid #808080;

        span {
          position: absolute;
          top: 9.5px;
          right: 20px;
          text-transform: uppercase;
          color: #FFF;
        }
      }

      .points-rewards {
        z-index: 3;
        float: right;
        box-sizing: border-box;
        width: 50%;
        text-indent: 8%;
        border-left: 1px solid rgba(128, 128, 128, .43);

        .reward-block {
          font: normal normal 400 13px/normal 'brandon-grotesque', Arial, 'Helvetica Neue', Helvetica, sans-serif;
          position: relative;
          z-index: 10;
          box-sizing: border-box;
          color: #FFF;

          &:last-child {
            border-bottom: none;
          }

          &:after {
            display: table;
            clear: both;
            content: '';
          }

          .reward-amount {
            font-weight: bold;
            position: relative;
            display: block;
            float: left;
            width: 50%;
            padding: 9px 0;

            .reward-tap-overlay {
              display: none;
            }
          }
        }

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          content: '';
          background-color: #000;
        }
      }

      &[data-brand='rco'],
      &[data-brand='14'],
      &.brand-id-14 {
        border-left: 1px solid #00E1BA;
      }

      &[data-brand='rco'] .brand-name,
      &[data-brand='14'] .brand-name,
      &.brand-id-14 .brand-name {
        border-top: 1px solid #00E1BA;
      }

      &[data-brand='rco'] .brand-name span,
      &[data-brand='14'] .brand-name span,
      &.brand-id-14 .brand-name span {
        color: #00E1BA;
      }

      &[data-brand='rco'] .points-rewards,
      &[data-brand='14'] .points-rewards,
      &.brand-id-14 .points-rewards {
        border-left: 1px solid #00E1BA;
      }

      &[data-brand='v76'],
      &[data-brand='13'],
      &.brand-id-13 {
        border-left: 1px solid #F3F281;
      }

      &[data-brand='v76'] .brand-name,
      &[data-brand='13'] .brand-name,
      &.brand-id-13 .brand-name {
        border-top: 1px solid #F3F281;
      }

      &[data-brand='v76'] .brand-name span,
      &[data-brand='13'] .brand-name span,
      &.brand-id-13 .brand-name span {
        color: #F3F281;
      }

      &[data-brand='v76'] .points-rewards,
      &[data-brand='13'] .points-rewards,
      &.brand-id-13 .points-rewards {
        border-left: 1px solid #F3F281;
      }

      &[data-brand='v76'] .points-rewards .reward-block .reward-amount.up:after,
      &[data-brand='13'] .points-rewards .reward-block .reward-amount.up:after,
      &.brand-id-13 .points-rewards .reward-block .reward-amount.up:after {
        border-bottom: 5px solid #F3F281;
      }

      &[data-brand='sc'],
      &[data-brand='74'],
      &.brand-id-74 {
        border-left: 1px solid #F84876;
      }

      &[data-brand='sc'] .brand-name,
      &[data-brand='74'] .brand-name,
      &.brand-id-74 .brand-name {
        border-top: 1px solid #F84876;
      }

      &[data-brand='sc'] .brand-name span,
      &[data-brand='74'] .brand-name span,
      &.brand-id-74 .brand-name span {
        color: #F84876;
      }

      &[data-brand='sc'] .points-rewards,
      &[data-brand='74'] .points-rewards,
      &.brand-id-74 .points-rewards {
        border-left: 1px solid #F84876;
      }

      &[data-brand='sc'] .points-rewards .reward-block .reward-amount.up:after,
      &[data-brand='74'] .points-rewards .reward-block .reward-amount.up:after,
      &.brand-id-74 .points-rewards .reward-block .reward-amount.up:after {
        border-bottom: 5px solid #F84876;
      }

      &[data-brand='lbp-corporate'],
      &[data-brand='85'],
      &.brand-id-85 {
        border-left: 1px solid #555467;
      }

      &[data-brand='lbp-corporate'] .brand-name,
      &[data-brand='85'] .brand-name,
      &.brand-id-85 .brand-name {
        border-top: 1px solid #555467;
      }

      &[data-brand='lbp-corporate'] .brand-name span,
      &[data-brand='85'] .brand-name span,
      &.brand-id-85 .brand-name span {
        color: #555467;
      }

      &[data-brand='lbp-corporate'] .points-rewards,
      &[data-brand='85'] .points-rewards,
      &.brand-id-85 .points-rewards {
        border-left: 1px solid #555467;
      }

      &[data-brand='lbp-corporate'] .points-rewards .reward-block .reward-amount.up:after,
      &[data-brand='85'] .points-rewards .reward-block .reward-amount.up:after,
      &.brand-id-85 .points-rewards .reward-block .reward-amount.up:after {
        border-bottom: 5px solid #555467;
      }
    }
  }

  .redeem-points {
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
    display: block;
    padding: 16px 12px;
    transition: all .5s ease;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    color: #897645;
    border: 1px solid #897645;

    .ss-icon {
      font-size: 12px;
      float: right;
    }
  }

  .slide-down-enter-to,
  .slide-down-leave {
    top: 0;
  }

  .slide-down-enter,
  .slide-down-leave-to {
    top: -120%;
  }
}

</style>
