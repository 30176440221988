import i18n from '@vue-storefront/i18n'

let FooterLinks = [
  {
    id: 1,
    name: i18n.t('LBP Pro'),
    url: '',
    subMenu: {
      links: [
        {
          id: 11,
          name: i18n.t('About'),
          url: '/about'
        },
        {
          id: 12,
          name: i18n.t('LBP Rewards'),
          url: '/rewards-program'
        },
        {
          id: 13,
          name: i18n.t('Press'),
          url: '/press'
        }
      ]
    }
  },
  {
    id: 2,
    name: i18n.t('Brands'),
    url: '',
    subMenu: {
      links: [
        {
          id: 22,
          name: i18n.t('R+Co'),
          url: '/brands/r-and-co'
        },
        {
          id: 23,
          name: i18n.t('R+Co Bleu'),
          url: '/brands/bleu'
        },
        {
          id: 24,
          name: i18n.t('R+Color'),
          url: '/brands/r-and-color'
        }
      ]
    }
  },
  {
    id: 3,
    name: i18n.t('Support'),
    url: '',
    subMenu: {
      links: [
        {
          id: 31,
          name: i18n.t('Delivery & Returns'),
          url: '/delivery-and-returns'
        },
        {
          id: 32,
          name: i18n.t('FAQ'),
          url: '/faq'
        },
        {
          id: 33,
          name: i18n.t('Contact'),
          url: '/contact'
        },
        {
          id: 34,
          name: i18n.t('Clear My Cache'),
          url: '/cache-clear',
          external: true
        }
      ]
    }
  }
]

export default FooterLinks
