<template>
  <div>
    <special-offer-list v-if="blok" :item="blok.content" class="hidden md:block" />
    <header class="site-header w-full bg-black text-white shadow-secondary relative z-header">
      <div class="site-header__container relative">
        <div class="container relative flex mx-auto xxl:px-0 px-md pb-xs xl:pb-0 pt-xs xl:pt-md">
          <logo class="block flex-none self-center mx-0 xl:mx-8 mt-2 mb-sm z-loader" />
          <div class="flex flex-row-reverse md:flex-row w-full">
            <HeaderMenu class="flex md:w-3/4" />
            <SearchBar v-if="searchBarEnabled" class="hidden md:flex md:flex-auto" />
            <nav class="flex site-header-nav w-4/5 md:w-1/4 justify-end">
              <ul class="list-none p-0 flex flex-row">
                <AccountMenu class="flex" />
                <MicrocartIcon class="flex" />
                <li class="leading-loose flex">
                  <SearchIcon class="z-loader" />
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <update/>
  </div>
</template>

<script>
import Logo from 'theme/components/core/Logo'
import HeaderMenu from 'theme/components/core/blocks/HeaderMenu/HeaderMenu'
import SearchIcon from 'theme/components/core/blocks/Header/SearchIcon'
import MicrocartIcon from 'theme/components/core/blocks/Header/MicrocartIcon'
import AccountMenu from 'theme/components/core/blocks/HeaderMenu/AccountMenu'
import StoryblokSingle from 'theme/mixins/StoryblokSingle'
import SpecialOfferList from 'theme/components/storyblok/Blocks/SpecialOfferList'
import Update from 'theme/components/core/blocks/Versioning/Update'
import SearchBar from 'theme/components/core/SearchBar'

export default {
  name: 'Header',
  components: {
    Update,
    Logo,
    HeaderMenu,
    SearchIcon,
    MicrocartIcon,
    AccountMenu,
    SpecialOfferList,
    SearchBar
  },
  mixins: [StoryblokSingle],
  data () {
    return {
      blokSlug: 'header-promo-widget',
      searchBarEnabled: false
    }
  }
}
</script>

<style lang="scss">
$screen-md: 768px;

@media screen and (max-width: #{$screen-md - 1}) {
  .site-header {
    height: 50px;
    &__container {
      &:before {
        @apply inset-0 absolute bg-black z-loader;
        content: "";
      }
    }
  }
}

@media print {
  .site-header {
    display: none !important;
  }
}

</style>
