<template>
  <header class="checkout-header bg-white relative z-header">
    <div class="pro_wrapper pro_gutter relative">
      <span class="logo-title absolute font-bold text-base leading-normal uppercase text-center block">
        <a
          href="javascript:void(0);"
          class="logo-link text-black hover:text-brown-2"
          @click="openReturnPopup"
        >
          {{ $t('Luxury brand') }} <br> {{ $t('partners') }}
        </a>
      </span>
      <nav class="pro_main_nav hidden pt-md lg:block">
        <ul class="pb-5 pt-5 ml-5 clearfix">
          <li
            v-for="(step, index) in steps"
            :key="index"
            class="pro_nav_item relative float-left inline-block m-0"
            :class="{
              'active': isActive(index) || isThankYouPage,
              'events-none': isThankYouPage
            }"
          >
            <router-link
              class="step-link text-black cursor-pointer tracking-lg bg-transparent font-bold text-h5 leading-loose uppercase inline-block"
              :to="localizedRoute({ name: step.path})"
            >
              {{ step.title }}
            </router-link>
          </li>
        </ul>
      </nav>
      <ul
        v-if="!isThankYouPage"
        class="return-list relative lg:absolute"
      >
        <li class="pro_nav_item relative float-right">
          <div
            class="return-link text-black inline-block font-bold text-h5 leading-loose uppercase tracking-lg relative cursor-pointer"
            @click="openReturnPopup()"
          >
            <span class="hidden sm:block">
              {{ $t('Return to cart') }}
            </span>
            <i class="ss-icon ss-cart absolute leading-loose"></i>
          </div>
        </li>
      </ul>
    </div>
    <return-to-cart-popup />
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import ReturnToCartPopup from 'theme/components/core/blocks/Checkout/ReturnToCartPopup'
import ButtonFull from 'theme/components/theme/ButtonFull'

export default {
  data () {
    return {
      steps: [
        {
          title: '1. Cart',
          path: 'cart'
        },
        {
          title: '2. Shipping & Payment',
          path: 'checkout'
        },
        {
          title: '3. Order Placed',
          path: 'checkout-success'
        }
      ],
      currentStep: 0
    }
  },
  components: {
    ReturnToCartPopup
  },
  mounted () {
    this.getSteps(this.routeName)
  },
  computed: {
    ...mapGetters({
      isThankYouPage: 'checkout/isThankYouPage'
    }),
    routeName () {
      return this.$route.name
    }
  },
  methods: {
    openReturnPopup () {
      this.$router.push({ name: 'cart' })
      // this.$bus.$emit('modal-hide', 'modal-return-to-cart')
    },
    isActive (index) {
      return index <= this.currentStep
    },
    getSteps (currentRoute) {
      this.steps.map((step, index) => {
        if (step.path === currentRoute) this.currentStep = index
      })
    }
  },
  watch: {
    routeName (newVal, oldVal) {
      this.getSteps(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
$grey-color: #e0e0e0;
$grey-2-color: #929292;
$deep-blue: #353640;

.checkout-header {
  box-shadow: 0 3px 5px rgba(0, 0, 0, .15);
  height: 100px;

  .pro_wrapper {
    margin: 0 auto;
    padding-left: 213px;
    padding-right: 50px;

    @screen lg {
      width: 1180px;
    }
  }

  .logo-title {
    left: 50px;
    top: 35px;
    width: 128px;
  }

  .logo-link {
    transition: color .5s ease;
  }

  .pro_main_nav {
    .pro_nav_item {
      &:before {
        @apply absolute w-full;
        content: '';
        left: 0;
        bottom: 0;
        height: 2px;
        background-color: $grey-color;
      }

      &.active {
        .step-link {
          @apply text-black pointer-events-auto cursor-pointer;

          &:before {
            @apply w-full absolute bg-brown-2;
            content: '';
            left: 0;
            bottom: 0;
            height: 2px;
            transition: width 0.25s linear;
          }
        }

        &.events-none {
          .step-link {
            @apply cursor-default pointer-events-none;
          }
        }
      }
    }
  }

  .step-link {
    @apply pointer-events-none cursor-default;
    color: $grey-2-color;
    padding: 15px 35px 15px 0;
    transition: color 0.25s linear;
  }

  .return-list {
    top: 15px;
    right: 0;

    .return-link {
      padding: 35px 53px 35px 25px;
      transition: color .25s ease, background-color 0.25s ease;

      &:hover {
        @screen sm {
          @apply text-white;
          background-color: $deep-blue
        }
      }
    }

    .ss-icon {
      top: 35px;
      right: 15px;
      font-size: 23px;
    }
  }
}
</style>
