<template>
  <div>
    <div class="bg-white">
      <div class="special-offers__wrap w-full mx-auto relative z-modal">
        <div class="special-offers">
          <div class="special-offers__head flex items-center bg-white">
            <div class="text-center bg-white w-1/5 px-xs">
              <h3 class="uppercase text-average xl:text-heading-page tracking-average leading-lg text-black font-normal">{{ item.main_title }}</h3>
            </div>
            <div class="pl-8.5 justify-between items-center flex w-4/5 bg-white py-4">
              <p class="text-base text-black font-sans tracking-normal font-bold max-w-md uppercase">{{ item.promo }}</p>
              <a href="javascript:void(0);" class="font-serif text-base text-black leading-loose tracking-tiny italic pr-4" @click.prevent="toggleMenu">
                  <span v-if="!isOpen">{{ linkTitle }}</span>
                  <span v-else>{{ closeTitle }}</span>
                  <i class="ss-icon text-h5 leading-h5 ml-xs mt-1 special-offers__icon ss-navigatedown" :class="{ 'special-offers__icon--active' : isOpen }"></i>
                </a>
            </div>
          </div>
        </div>
        <transition name="slide-fade">
          <div class="absolute xl:left-0 xl:right-0 left-10px right-10px scroll-area" v-if="isOpen">
            <sb-render
              v-for="(_item, index) in item.items"
              :key="_item._uid"
              :class="{ 'special-offer--even' : index % 2 == 0, 'special-offer--odd' : index % 2 != 0 }"
              :item="_item"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Blok
} from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'SpecialOfferList',
  extends: Blok,
  data () {
    return {
      isOpen: false,
      closeTitle: 'Close',
      linkTitle: 'Click here for details'
    }
  },
  beforeMount () {
    this.$bus.$on('ui-overlay-close', this.closeMenu)
  },
  beforeDestroy () {
    this.$bus.$off('ui-overlay-close', this.closeMenu)
  },
  methods: {
    toggleMenu () {
      this.isOpen = !this.isOpen
      this.$store.commit('ui/setOverlay', this.isOpen)
    },
    closeMenu () {
      this.isOpen = false
    }
  }
}

</script>
<style lang="scss" scoped>
@special-offer: ~'.special-offer';

.special-offers {
  &__wrap {
    @apply px-sm;
    max-width: 1180px;
    @screen xl {
      @apply px-0;
    }
  }
  &__icon {
    @apply inline-block;
    transform: rotate(0deg);
    transition: transform .4s cubic-bezier(.33, .07, 0, .9) 0s;
    &--active {
      transform: rotate(-180deg);
    }
  }
}

.scroll-area {
  max-height: calc(100vh - 58px);
  overflow-y: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
